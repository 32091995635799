<template>
  <div>
    <Rates
      :rates="rates"
      @saveRate="saveRate"
      @createRate="createRate"
      @deleteRate="deleteRate"
      :delete="true"
      :create="true"
      :fee="true"
      title="Payment type"
      rate="Rate"
      ref="removeNewLine"></Rates>
    <v-snackbars
        :messages.sync="messages"
        color="red"
        timeout="2000"
        multi-line
        max-width="350"
        left
        rounded="lg"
        light
        elevation="4"
        content-class="snackbar"
      >
      <template v-slot:default="{ message }">
        <strong>{{ message.title }}</strong>
        <p>{{ message.text }}</p>
        <v-row v-if="message.title === 'Error'" class="ma-0 justify-end">
          <v-btn
            class="rounded-lg fz-12 fw-600 text-none border-none"
            outlined
            height="26"
            elevation="0"
            @click="doCopy('Error', message.text)"
          >Copy</v-btn>
        </v-row>
      </template>
    </v-snackbars>
    <div class="preloader" v-if="loading">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import Rates from '@/components/App/Plans/Rates'
import gql from 'graphql-tag'
import VSnackbars from 'v-snackbars'

export default {
  components: { Rates, 'v-snackbars': VSnackbars },
  data () {
    return {
      rates: [],
      snackbar: false,
      loading: false,
      messages: []
    }
  },
  methods: {
    async allPaymentTypes () {
      this.loading = true
      await this.$apollo.query({
        query: gql`query PlansPaymentRates {
          pricing {
            allPaymentTypes {
              name
              rate
              id
              readOnly
            }
          }
        }`,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.rates = data.data.pricing.allPaymentTypes
        this.rates.map(el => {
          el.value = el.rate
        })
        this.loading = false
      }).catch((err) => {
        this.addSnack('item.name', 'Error', err)
        this.loading = false
        console.log(err)
      })
    },
    async saveRate (item) {
      (await this.$apollo.mutate({
        mutation: gql`mutation PlansPaymentRatesUpdate($input: PaymentTypeUpdateInput! ) {
          pricing {
            paymentType {
              update(input: $input) {
                id
                name
                rate
              }
            }
          }
        }`,
        // Parameters
        variables: {
          input: {
            id: item.id,
            name: item.name,
            rate: item.value
          }
        }
      })
        .then((data) => {
          if (data.data.pricing.paymentType.update.name === 'Fee') this.$store.commit('updateDefaultFee', data.data.pricing.paymentType.update.rate)
          this.addSnack(item.name, 'Success', item.name + ' was updated with success')
        })
        .catch((err) => {
          this.addSnack('item.name', 'Error', err)
        }))
    },
    async deleteRate (item) {
      (await this.$apollo.mutate({
        mutation: gql`mutation PlansPaymentRatesDelete($id: [ID!]!) {
          pricing {
            paymentType {
              delete(id: $id)
            }
          }
        }`,
        // Parameters
        variables: {
          id: item.id
        }
      })
        .then(() => {
          this.addSnack(item.name, 'Success', item.name + ' was deleted with success')
          const index = this.rates.indexOf(this.rates.find(el => el.id === item.id))
          if (index >= 0) this.rates.splice(index, 1)
        })
        .catch((err) => {
          console.log(err)
          this.addSnack('item.name', 'Error', err)
        }))
    },
    async createRate (item) {
      (await this.$apollo.mutate({
        mutation: gql`mutation PlansPaymentRatesCreate($input: PaymentTypeCreateInput! ) {
          pricing {
            paymentType {
              create(input: $input) {
                id
                name
                rate
              }
            }
          }
        }`,
        // Parameters
        variables: {
          input: {
            name: item.name,
            rate: item.value
          }
        }
      })
        .then((data) => {
          this.addSnack(item.name, 'Success', item.name + ' was updated with success')
          this.rates.push(data.data.pricing.paymentType.create)
          this.rates.map(el => {
            el.value = el.rate
          })
          this.$refs.removeNewLine.removeNewLine()
        })
        .catch((err) => {
          console.log(item)
          this.addSnack('item.name', 'Error', err)
        }))
    },
    addSnack (item, result, text) {
      this.messages.push({ title: result, text: text })
    },
    doCopy (action, value) {
      const vm = this
      this.$copyText(value).then(function () {
        vm.addSnack(value, 'Success', action + ' text copied')
      }, function (e) {
        console.log(e)
      })
    }
  },
  created () {
    this.allPaymentTypes()
  }
}
</script>

<style scoped>

</style>
