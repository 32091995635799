<template>
  <v-col class="mx-0 px-7 my-3">
    <v-row class="ma-0 title height-50">
      <div class="type-input-width mt-auto px-3"><p class="fz-14 font-weight-bold">{{ title }}</p></div>
      <div class="mt-auto px-3"><p class="fz-14 font-weight-bold">{{ rate }}</p></div>
    </v-row>
    <v-form ref="form">
      <v-row v-if="feeRate" class="ma-0 height-50">
        <v-col>
          <v-row>
            <div class="type-input-width border-l-none border-b-none border-lightgray height-50">
              <v-text-field
                v-model="feeRate.name"
                readonly
                solo
                flat
                class="fz-14 moveup-details"
                :rules="[rules.required]"
              ></v-text-field>
            </div>
            <div class="rate-input-width border-l-none border-b-none border-r-none height-50 border-lightgray"
                 :class="{'rate-input-without-delete': !deleteVal }">
              <v-text-field
                :value="focus ? Math.round(feeRate.value * 100) / 100 : Math.round(feeRate.value * 100) / 100 + '%'"
                @focus="focus = true"
                @blur="focus = false; editRateNow($event, feeRate)"
                @keyup="updateRate($event, feeRate)"
                solo
                flat
                class="fz-14 moveup-details"
                :rules="[rules.required, rules.positive]"
              ></v-text-field>
            </div>
            <div class="border-lightgray border-b-none border-r-none width-50 height-50">
            </div>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-for="rate in rateArr" :key="rate.id" class="ma-0 height-50">
        <v-col>
          <v-row>
            <div class="type-input-width border-l-none border-b-none border-lightgray height-50">
              <v-text-field
                v-model="rate.name"
                @keyup="updateLine($event, rate)"
                @blur="editNow($event, rate)"
                :readonly="rate.readOnly"
                solo
                flat
                class="fz-14 moveup-details"
                :rules="[rules.required, rules.minCounter]"
              ></v-text-field>
            </div>
            <div class="rate-input-width border-l-none border-b-none border-r-none height-50 border-lightgray"
                 :class="{'rate-input-without-delete': !deleteVal }">
              <v-text-field
                :value="focus ? Math.round(rate.value * 100) / 100 : Math.round(rate.value * 100) / 100 + '%'"
                @focus="focus = true"
                @blur="focus = false; editRateNow($event, rate)"
                @keyup="updateRate($event, rate)"
                solo
                flat
                class="fz-14 moveup-details"
                :rules="[rules.required, rules.positive]"
              ></v-text-field>
            </div>
            <div v-if="!rate.readOnly" class="border-lightgray border-b-none border-r-none width-50 height-50">
              <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="370"
                      content-class="rounded-xl"
                      overlay-color="rgba(9, 24, 73, 0.2)"
                      overlay-opacity="1"
                    >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          v-bind="attrs"
                          v-on="on"
                          elevation="0"
                          small
                          icon
                          fab
                          class="height-50 width-50 mx-auto"
                        >
                          <v-icon size="20"  class="stroke-gray">$delete</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:default="dialog">
                      <v-card class="pa-7">
                        <v-card-text class="py-0">
                          <div class="fz-18 text-center text-black">Do you really want to delete {{ rate.name }}?</div>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions class="justify-space-between pa-0">
                          <v-btn
                            class="rounded-lg text-none fz-16 fw-600"
                            outlined
                            width="150"
                            height="40"
                            @click="dialog.value = false"
                          >Return</v-btn>
                          <v-btn
                            elevation="0"
                            color="#FF0000"
                            width="150"
                            height="40"
                            class="rounded-lg white--text text-none fz-16 fw-600"
                            @click="removeLine(rate)"
                          >
                            <v-icon left class="stroke-white">
                              $delete
                            </v-icon>
                            Delete</v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <v-form ref="formNewLine">
      <v-row v-if="newLine.show" class="ma-0 height-50">
      <div class="type-input-width border-b-none border-lightgray border-l-none height-50 fz-14">
        <v-text-field
          v-model="newLine.name"
          @keyup="saveNewLine()"
          @blur="saveNewLineNow"
          solo
          flat
          :rules="[rules.required, rules.minCounter]"
          class="fz-14 moveup-details"
        ></v-text-field>
      </div>
      <div class="rate-input-width height-50 border-b-none border-r-none border-l-none border-lightgray">
        <v-text-field
          v-model="newLine.value"
          @keyup="saveNewLine()"
          @blur="saveNewLineNow"
          solo
          flat
          :rules="[rules.required, rules.positive]"
          class="fz-14 moveup-details"
        ></v-text-field>
      </div>
      <div class="border-lightgray border-b-none border-r-none width-50 height-50">
        <v-btn
          @click="removeNewLine"
          elevation="0"
          small
          icon
          fab
          class="height-50 width-50 mx-auto"
          >
            <v-icon size="20" class="stroke-gray">$delete</v-icon>
          </v-btn>
      </div>
    </v-row>
    </v-form>
    <v-row v-if="createVal" class="border-lightgray border-l-none border-r-none ma-0">
      <v-btn height="50" text tile :disabled="newLine.show" @click="newLine.show = true" color="#7552CC" class="text-none px-3 fz-14 font-weight-medium">
        <b class="fz-16 fw-600 mr-1"> + </b>Add new line
      </v-btn>
    </v-row>
    <v-row v-else class="border-lightgray border-b-none border-l-none border-r-none ma-0">
    </v-row>
  </v-col>
</template>

<script>
import _ from 'lodash'

const saveNewLine = _.debounce(function () {
  const vm = this
  if (this.$refs.formNewLine.validate() && this.saveNewLine !== '') {
    vm.$emit('createRate', vm.newLine)
  }
}, 2000)
const editLine = _.debounce(function (item) {
  this.$emit('saveRate', item)
}, 1500)

export default {
  props: ['rates', 'delete', 'create', 'title', 'rate', 'fee'],
  data () {
    return {
      focus: false,
      newLine: {
        show: false,
        name: '',
        value: ''
      },
      rules: {
        required: value => (value !== null && String(value).length > 0) || 'Required.',
        positive: value => Number.parseFloat(value) >= 0 || 'Required positive number',
        minCounter: value => value.trim().length >= 2 || 'Min 2 characters'
      }
    }
  },
  computed: {
    deleteVal () {
      return this.delete
    },
    createVal () {
      return this.create
    },
    feeRate () {
      let arr
      if (this.fee) {
        arr = this.rates.find(el => el.readOnly === true)
        return arr
      } else return null
    },
    rateArr () {
      const index = this.rates.indexOf(this.rates.find(el => el.readOnly === true))
      const arr = this.rates.slice()
      if (this.fee && index !== -1) arr.splice(index, 1)
      return arr
    }
  },
  methods: {
    removeLine (item) {
      this.$emit('deleteRate', item)
    },
    removeNewLine () {
      this.newLine.show = false
      this.newLine.name = ''
      this.newLine.value = ''
    },
    saveNewLineNow () {
      const vm = this
      if (this.$refs.formNewLine.validate()) {
        vm.$emit('createRate', vm.newLine)
        saveNewLine.cancel()
      }
    },
    saveNewLine,
    editLine,
    updateLine (event, rate) {
      if (this.$refs.form.validate()) {
        if (event !== rate.name) {
          this.editLine(rate)
        }
      }
    },
    updateRate (event, rate) {
      if (this.$refs.form.validate()) {
        if (event.target.value !== rate.value + '' && event.target.value !== Math.round(rate.value * 100) / 100 + '' && event.target.value !== Math.round(rate.value * 100) / 100 + '%') {
          rate.value = Number.parseFloat(event.target.value)
          this.editLine(rate)
        }
      }
    },
    editNow (event, rate) {
      if (this.$refs.form.validate()) {
        if (event.target.value !== rate.name) {
          this.$emit('saveRate', rate)
        }
      }
    },
    editRateNow (event, rate) {
      if (this.$refs.form.validate()) {
        if (event.target.value !== rate.value + '' && event.target.value !== Math.round(rate.value * 100) / 100 + '' && event.target.value !== Math.round(rate.value * 100) / 100 + '%') {
          rate.value = Number.parseFloat(event.target.value)
          this.$emit('saveRate', rate)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.type-input-width {
  width: 70% !important;
}
.rate-input-width {
  width: calc(30% - 50px) !important;
}
.rate-input-without-delete {
  width: 30% !important;
}
.width-50 {
  width: 50px;
}
</style>
